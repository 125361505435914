img[data-sizes="auto"] { 
    display: block; 
    width: 100%; 
}

@media(min-width: 500px) { 
    .top_responsive { 
        width: 468px; 
        height: 60px;
        text-align: center;
        margin: 0 auto;        
    } 
}

@media(min-width: 800px) { 
    .top_responsive {
        width: 728px; 
        height: 90px;
        text-align: center;
        margin: 0 auto;         
    } 
}

.bottom_responsive {
    text-align: center;
    margin: 0 auto;
}

.content_responsive {
    text-align: center;
    margin: 0 auto;   
}

#a-video {
    margin: 0 auto;    
    max-width: 480px;
    text-align: center;     
}

/* MV CSS */
@media only screen and (max-width: 300px) {
    table {
     display: block;
     overflow-x: auto;
     white-space: nowrap;
  }
}
@media only screen and (max-width: 359px) {
    .medium-12.columns {
      padding-left: 10px !important;
      padding-right: 10px !important;
  }
}
/* END OF CSS */