.author-bio {
    background-color: $ci-11;
    border-left: $border-left-width solid $ci-8;
    border-radius: 3px;
    display: flex;
    font-size: 1.2rem; 
    margin: 1.75em 0;
    padding: 10px;
    width:100%;
}

.author-bio a,
.author-bio a:hover {
    border: none;
    text-decoration: none;
}

.author-photo {
    flex: 0 0 auto;
}

.author-photo img {
    border: 3px solid $grey-3;
    border-radius: 50%;
}

.author-info {
    flex: 1;    
    margin-left: 15px;
}

.author-name {
    float: left;
    font-size: 1.15em;
    font-weight: bold;
    margin-right: 20px;
}

.author-soc {
    font-size: 1.15em;
}

.author-soc a {
    margin-right: 5px;
}

.author-caption {
    line-height: 2;
}