.aff-notice {    
    color: $grey-10;
    font-style: italic;
    margin: 2px;
    padding: 10px;   
}

.aff-notice p {
    margin-bottom: 0;    
}

.product {
    width: 100%;
    display: table;
    overflow:hidden;
    margin-bottom: 3em;
    padding: 5px;
    background-color: #fff;
    border: 4px $grey-3 solid;
}

.productimage {
    display: table-cell;
    vertical-align: middle;
    width: 40%;     
}

.productimage img {
    margin: auto;
    display: block;
}

.productinfowrapper {
    display: table-cell;
    overflow: hidden; 
    vertical-align: top;
    width: 60%;
}
.productinfo {
    height: 75%;
}
.producttitle {
    font-size: 1.4em;
    font-weight: bold;
}
.productdescription li {
    margin-bottom: calc(0.1rem + 0.3333vw);
    list-style: square inside url("/images/square-bullet.png")
}

.price-btn {
    background-color: #ff9900;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4em;
    font-weight: bold;
    height: 50px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    min-width: 200px;
}

.price-btn:hover {
    background-color: #a06000;
    color: white;
}

.productprice {
    text-align: center;
    vertical-align: bottom; 
    overflow: hidden;      
}

.productprice a:link,
.productprice a:visited,
.productprice a:hover,
.productprice a:active  {
    border: 0;
    text-decoration: none;
}