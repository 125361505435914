@import "global";

div.relatedposts {
    background-color: $grey-0;
    border: 1px solid $grey-2;    
    font-style: italic;
    margin-bottom: 1.25rem;
    padding-top: 1.25rem;   
}

div.relatedposts .header {    
    font-size: 1.15rem;
    font-weight: bold;
    margin-bottom: .3rem;
    margin-left: 1rem;
}

div.relatedposts ul {
    list-style-type: none;
    margin-left: 1rem;
    margin-right: 1rem;   
    padding: 0;
}

div.relatedposts ul li {
    margin-bottom: 0;
    padding-top: .3rem;
    padding-bottom: .3rem;
}

div.relatedposts a {
    font-size: 1.1rem;
}