@import "global";

#about .social-icons li a {    
    border: 2px solid;
    border-radius: 50%;
    display: block;
    font-size: rem-calc(50);
    margin-top: 10px;
    padding: 10px;
    text-align: center;
}