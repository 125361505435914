aside .panel {
  background-color: inherit;
  border: 0;
  padding: 0;
}

aside .panel h3 {
  font-size: 1.2em;
}

aside .panel .aside-nav-item {
  display: block;
  float: none;
  outline: 0;
  padding: 6px 0;
  text-align: left;
  white-space: normal;
  width: 100%;
}

.sidebar-panel {
  padding: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.sidebar-panel.active {
  max-height: max-content;
}

/* Mediavine Sidebar Fix */
@media only screen and (max-width: 767px) {
  .medium-push-4 {
      width: 100%!important;
      left:unset!important;
  }
}

.api-sidebar {
    position: sticky;
    top: 140px;
    height: calc(100vh - 145px);
    overflow-y: auto;
    border: 1px solid $code-border-color;
    border-left: $border-left-width solid $code-border-color;
    border-radius: $global-radius;
}