/* TOC – Color Variables

- Basics
- Corporate Identity Colorpalette
- Foundation Color Variables
- Grey Scale
- Topbar-Navigation
- Footer
- Code

*/



/* Basics
------------------------------------------------------------------- */

$text-color:       	#111;
$body-font-color: 	$text-color;
$body-bg: 			#fdfdfd;



/* Corporate Identity Colorpalette
   https://color.adobe.com/de/Flat-Design-Colors-v2-color-theme-4341903/
------------------------------------------------------------------- */

$ci-1: #334D5C;		// dark turquoise
$ci-2: #527b92;		// turquoise
$ci-3: #EFC94C;		// yellow
$ci-4: #E27A3F;		// orange
$ci-5: #DF4949;		// red
$ci-6: #A1D044;     // green
$ci-7: #6191ad;
$ci-8: #80b4d1;
$ci-9: #0368A2;
$ci-10: #dcedc8;
$ci-11: #eaf2f8;
$ci-12:  #ffcdd2;
$ci-13: #ffe0b2;


/* Foundation Color Variables
------------------------------------------------------------------- */

$primary-color: $ci-1;
$secondary-color: $ci-6;
$anchor-color: $ci-9;
$alert-color: $ci-12;
$success-color: $ci-6;
$warning-color: $ci-13;
$info-color: $ci-10;



/* Grey Scale
------------------------------------------------------------------- */

$grey-0: #F1F1F1;
$grey-1: #E4E4E4;
$grey-2: #D7D7D7;
$grey-3: #CBCBCB;
$grey-4: #BEBEBE;
$grey-5: #A4A4A4;
$grey-6: #979797;
$grey-7: #8B8B8B;
$grey-8: #7E7E7E;
$grey-9: #646464;
$grey-10: #575757;
$grey-11: #4B4B4B;
$grey-12: #3E3E3E;
$grey-13: #313131;
$grey-14: #242424;
$grey-15: #171717;
$grey-16: #0B0B0B;
$grey-17: #f6f6f6;



/* Topbar-Navigation
------------------------------------------------------------------- */

$topbar-bg-color: $ci-8; //#fff;

$topbar-dropdown-toggle-color: $ci-1;

$topbar-link-color: #000;
$topbar-link-color-hover: #000;
$topbar-link-color-active: #000; //#fff;
$topbar-link-color-active-hover: #fff;

$topbar-dropdown-label-color: $ci-2;
$topbar-dropdown-link-bg-hover: $ci-8;

$topbar-link-bg-active: $ci-7; //$grey-15; // Active Navigation Link
$topbar-link-bg-hover: $ci-7;
$topbar-link-bg-active-hover: $ci-1;


$topbar-dropdown-bg: $grey-1; // Background Mobile Navigation
$topbar-dropdown-link-color: #000;
$topbar-dropdown-link-bg: $grey-2;

$topbar-menu-link-color-toggled: $ci-1;
$topbar-menu-icon-color-toggled: $ci-1;
$topbar-menu-link-color: #000;
$topbar-menu-icon-color: #000;
$topbar-menu-link-color-toggled: $ci-1;
$topbar-menu-icon-color-toggled: $ci-1;



/* Footer
------------------------------------------------------------------- */

$footer-bg: $grey-1;
$footer-color: #fff;
$footer-link-color: $ci-9;


$subfooter-bg: $grey-13;
$subfooter-color: $grey-1;
$subfooter-link-color: $grey-1;



/* Code
------------------------------------------------------------------- */

/*$code-background-color: scale-color($secondary-color, $lightness: 70%);*/
$code-background-color:  scale-color($grey-2, $lightness: 80%);
$code-border-color: $grey-2;

$highlight-background: #ffffff;
$highlight-comment: #999988;
$highlight-error: #a61717;
$highlight-comment-special: #999999;
$highlight-deleted: #000000;
$highlight-error-2: #aa0000;
$highlight-literal-string: #d14;
$highlight-literal-number: #009999;
$highlight-name-attribut: #008080;
$highlight-error-background: #e3d2d2;
$highlight-generic-deleted: #ffdddd;
$highlight-generic-deleted-specific: #ffaaaa;
$highlight-generic-inserted: #ddffdd;
$highlight-generic-inserted-specific: #aaffaa;
$highlight-generic-output: #888888;
$highlight-generic-prompt: #555555;
$highlight-subheading: #aaaaaa;
$highlight-keyword-type: #445588;
$highlight-name-builtin: #0086B3;
$highlight-name-class: #445588;
$highlight-name-entity: #800080;
$highlight-name-exception: #990000;
$highlight-name-function: #990000;
$highlight-name-namespace: #555555;
$highlight-name-tag: #000080;
$highlight-text-whitespace: #bbbbbb;
$highlight-literal-string-regex: #009926;
$highlight-literal-string-symbol: #990073;
