#af-form-462372098.af-form {
    background-color: $ci-11;
    border-left: $border-left-width solid $ci-8;
    border-radius: $global-radius;
    margin: 1.75em 0;    
    width: 100%;
}

#af-header-462372098 {
    background-color: $ci-11;
    padding: 10px;
  }

#af-body-462372098.af-body {
  margin-bottom: 15px;
  padding: 10px;
}

#af-form-462372098 .bodyText {
  border-bottom: 1px solid $ci-1;
}

#af-form-462372098 .bodytitle {
    color: $ci-1;
    font-weight: bold;
    font-size: 2rem;
  }

#af-form-462372098 .subscribe-icon {
  color: $ci-1;
  font-size: 2rem;  
  padding-right: 5px;
}

#af-form-462372098 .af-element.emailcontainer {
    margin-top: 10px;
}

#af-form-462372098 .previewLabel {
    color: $ci-1;
    font-size: 1.15rem;
    font-weight: bold;
}

#af-form-462372098 .af-textWrap input {
    margin: 0;
}

#af-form-462372098 .choice {
  color: $ci-1;
  font-size: 1.15rem;
  font-weight: bold;
}

input[type="checkbox"] + label {
  margin-left: 0;
  margin-right: 3rem;
}

input[type="checkbox"] {
  width: 1.15rem;
  height: 1.15rem;
}

#af-form-462372098 .checkbox-input {
  display: flex;
  margin-top: 10px;
}

#af-body-462372098 .buttonContainer {
    align-self: flex-end;
}

