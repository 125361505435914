.sharebar {
    font-size: 32px;
}

.sharebar-social {
    display: inline-block;
    margin: 10px 0;
    vertical-align: middle;
}

.sharebar-bmc, .sharebar-subscribe {
    display: inline-block;
    margin: 10px 5px;
    vertical-align: middle;
}

.sharebar-social a, .sharebar-social a:hover {
    border: 2px solid;
    border-radius: 50%;
    padding: 10px;
    margin-right: 5px;
}

.width20 {
    width: 20%;
}

.bmc-btn,
.subscribe-btn {
    border: 2pt solid #0368A2 !important;    
}

.bmc-btn:hover,
.subscribe-btn:hover {
    border: 2pt solid #000 !important;
}

.bmc-btn:hover svg {
    filter: brightness(0) saturate(100%);
}

.bmc-btn svg {
    height: 32px !important;
    margin-bottom: 0px !important;
    box-shadow: none !important;
    border: none !important;
    vertical-align: middle !important;
    transform: scale(0.9);
    flex-shrink: 0;
}

.bmc-btn,
.subscribe-btn {    
    color: #0368A2;    
    height: 60px;
    border-radius: 12px;
    font-size: 24px;
    font-weight: Bold;
    border: none;
    padding: 0px 24px;
    line-height: 27px;
    text-decoration: none !important;
    display: inline-flex !important;
    align-items: center;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.bmc-btn:hover, 
.bmc-btn:active,
.bmc-btn:focus,
.subscribe-btn:hover,
.subscribe-btn:active,
.subscribe-btn:focus
{
    text-decoration: none !important;
    cursor: pointer;
}

.bmc-btn-text,
.subscribe-text {    
    display: inline-block;
    line-height: 0;
    width: 100%;
    flex-shrink: 0;
}

.bmc-btn-text {
    margin-left: 8px;
}

.logo-outline {
    fill: #0368A2;
}

.logo-coffee {
    fill: #0368A2;
}

figure img {
    box-shadow: .3rem .3rem .5rem #ccc;
    border-radius: 5px;    
}

figure img.noshadow {
    box-shadow: none; 
}

.follow-box {
    line-height: 2.5rem;
}

.follow-text {
    float: left;
    font-size: 2rem;
    margin-right: 20px;
}

.follow-socials {
    font-size: 2.5rem;
}

@media(min-width: 1525px) {
    .left-gutter-container {
        position: fixed;
        z-index: 20;
        left: 100px;
        top: 150px;
        bottom: 100px;        
    }
    .left-gutter-image {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        max-width: 160px;
    }
}

@media only screen and (max-width:1525px) {
    .left-gutter-container,
    .left-gutter-image {
        display:none;
    }
}

.bottom-container {
    position: fixed;
    z-index: 20;
    bottom: 0;
    text-align: center;
    width: 100%;    
}

.bottom-image {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    max-width: 728px;
    width:100%;
}

#examples {
    margin: 1.75em 0;
}

/* Style the tab */
.tab {    
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: $ci-8;
    border-radius: 3px;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    background-color: $ci-8;
    color: $topbar-link-color;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    margin: 0;
    transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: $ci-2;
    color: $grey-0;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: $ci-2;
    color: $grey-0;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
} 

.tabcontent pre {
    background-color: $body-bg;
    border: none;
    word-wrap: normal;
    overflow-x: auto;
    white-space: pre;       
}

.tabcontent pre.prettyprint {
    border: none;
    padding: 20px 0; 
}

.tabcontent pre.prettyprint li {
    margin: 0;
}

pre.prettyprint {
    border: 1px solid #D7D7D7 !important;
    border-left: $border-left-width solid #D7D7D7 !important;
    padding: 5px !important;
}

article .footnotes li {
    margin-bottom: 1rem;
    line-height: 1.5rem;
}